
@media screen and (min-width:991px){
  #main-nav-mobi{display:none !important}
  #main-nav{display:block !important}
  .mobile-button{display:none !important}
}
@media screen and (max-width:990px){
  #main-nav{display:none !important}
  .mobile-button{display:block !important}
  body.is_light .homepage{background: #fff !important;}
  #main-nav > ul > li > a {font-size: 14px;}
  
  /* body.is_light{background:#fff !important;} */
    body.is_dark{background:#1f1f2c !important}
    body.is_light .homepage{background:#fff !important;}
    body.is_dark .homepage{background:#1f1f2c !important}
}
@media only screen and (max-width: 1600px) {
  .header #site-header-inner #site-logo {
    margin-right: 30px;
  }
  .tf-item-detail-inner .image {
    width: 40%;
    margin-right: 30px;
  }
  .tf-item-detail-inner.style-2 .current-bid {
    margin-right: 0;
  }
  .tf-slider-item .image .card-infor {
    margin-left: 15%;
  }
}
@media only screen and (max-width: 1366px) {
  #main-nav > ul > li {
    padding: 31px 15px 31px 15px;
  }
  .header #site-header-inner .header-right .tf-button {
    padding: 11px 15px;
  }
  .tf-blog .tf-blog-item .title {
    flex-wrap: wrap;
  }
  .detail-inner .image.style-2 {
    margin-right: 0;
  }
  .detail-inner .image.style-2 img {
    width: 100%;
    margin-bottom: 20px;
  }
  .detail-wrap .detail-inner {
    width: 65%;
    padding-right: 30px;
  }
  .detail-wrap .side-bar {
    width: 35%;
  }
  .dashboard-user {
    margin-bottom: 50px;
  }
  .tf-explore-sidebar-wrap .sc-product {
    width: calc(50% - 30px);
  }
  .sidebar.sidebar-explore {
    padding-right: 0px;
  }
  .tf-explore-sidebar-wrap.style-2 .sc-product {
    width: calc(50% - 30px);
    margin-left: 30px;
  }
  .banner-liver-auction-wrap .image .img1 {
    right: 0;
  }
  .tf-slider-item.style-2 .content-inner,
.tf-slider-item .content-inner {
    padding-top: 0;
    width: 50%;
  }
  .tf-slider-item .image {
    width: 50%;
    margin-left: 100px;
  }
  .tf-slider-item .image .img-slider-main {
    width: 70%;
  }
  .tf-slider-item .image .card-infor {
    margin-left: 0%;
    padding: 15px;
  }
  .tf-slider-item.style-5 .image {
    margin-left: 0px;
  }
  .tf-slider-item.style-5 .image .img-slider {
    width: 40%;
  }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-next {
    right: 50px;
  }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-prev {
    right: 90px;
    left: auto;
  }
  .tf-slider-item.style-6 {
    padding: 150px 15px 124px 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .header #site-header-inner .header-right .user {
    display: none;
  }
  .tf-item-detail-inner .image video {
    max-height: 364px !important; 
    min-height: 364px !important;
  }
  .tf-item-detail-inner .image audio {
    max-height: 364px !important;
    min-height: 364px !important;
  }
  .tf-item-detail-inner .image img {
    max-height: 364px !important;
    min-height: 364px !important;
  }
  .footer .widget.widget-menu .menu {
    margin-left: 20px !important;
    margin-right: 20px;
  }
  /* .tf-filter-container .sc-product .bottom .details-product .current-bid .price .cash {
    font-size: 13px !important;
  } */
  body .top-banner .ani4 {
    max-width: 400px;
    min-width: 400px;
    max-height: 400px;
    min-height: 400px;
    object-fit: cover;
     margin: auto;
  }
  body .inventory .table-ranking .content-ranking .col-rankingg.notification{width:150px !important}
  .dashboard-content.inventory .inner-content .table-ranking .content-ranking .col-rankingg.nofication{width:75% !important}
body .top-banner .ani4 img {

    max-width: 100%;
    min-width: 100%;
    max-height: 400px;
    min-height: 400px;
    object-fit: cover;

}
  #main-nav > ul > li {
    padding: 31px 15px 31px 5px !important;
}
.tf-connect{display:none !important}
  .footer .widget.widget-infor {
    padding-right: 0;
  }
  .footer .widget.widget-infor .social-item {
    flex-wrap: wrap;
  }
  .footer .widget.widget-infor .social-item li {
    margin-bottom: 10px;
  }
  .footer .widget.widget-menu .menu {
    margin-left: 0 !important;
    margin-right: 40px;
  }
  .footer .widget.widget-menu .menu:last-child {
    margin-right: 0;
  }
  .footer .widget.widget-subcribe {
    margin-left: 0;
  }
  .tf-blog .main-content {
    margin-right: 0;
  }
  .tf-banner-create .content-banner {
    margin-left: 0;
  }
  .tf-banner-create.banner2 .content-banner p,
.tf-banner-create.banner2 .content-banner h2,
.tf-banner-create.banner3 .content-banner,
.tf-banner-create.banner3 .content-banner p,
.tf-banner-create.banner2.style2 .content-banner p {
    margin-right: 0;
  }
  .tf-banner-create.banner2 .content-banner .star {
    left: 0;
  }
  .tf-banner-create.slide .content-slide {
    right: -9.5%;
  }
  .add-nft-inner {
    padding-right: 0px;
  }
  .filter-menu li {
    padding: 7.5px 10px;
    margin-right: 5px;
  }
  .tf-slider-item.style-4 .content-inner {
    padding-top: 0;
  }
  .tf-slider-item.style-4 .content-inner form {
    margin-right: 0;
  }
  .tf-slider-item.style-4 .content-inner .sub-heading {
    padding-right: 0;
  }
  .tf-slider-item.style-4 .content-inner p {
    padding-right: 0;
  }

}
@media only screen and (max-width: 1100px) {
  .header #site-header-inner .header-right .tf-button {
    display: none;
  }
  .footer .widget.widget-menu {
    flex-wrap: wrap;
  }
  .footer .widget.widget-menu .menu {
    width: 50%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .top-menu {
    flex-wrap: wrap;
  }
  .top-menu .filter-menu li {
    margin-bottom: 20px;
  }
  .tf-baner-live-auction.style-2 .banner-liver-auction-wrap .image .img1 {
    bottom: 0%;
    right: 0%;
    width: 29%;
  }
  .tf-item-detail-inner.style-2 .current-bid {
    flex-wrap: wrap;
  }
  .countdown.style-2 {
    margin-bottom: 20px;
  }
  .tf-ranking {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .tf-ranking .content-ranking, .tf-ranking .title-ranking {
    width: 1100px;
  }
  .slider-home-3.swiper-container {
    width: 100% !important;
  }
  .slider-thump .swiper-slide.swiper-slide-active {
    width: 180px !important;
  }
  .tf-slider-item.style-6 {
    display: block;
  }
  .tf-slider-item.style-6 .content-inner {
    padding-top: 0;
    width: 100%;
    margin-bottom: 50px;
  }
  .tf-slider-item.style-6 .image {
    width: 100%;
    justify-content: flex-start;
  }
  .tf-slider-item.style-6 .image .sc-product {
    width: 35%;
  }
  .tf-slider-item.style-6 {
    padding: 150px 0px 124px 0px;
  }
  .tf-slider-item.style-6 .content-inner .btn-slider {
    margin-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .mobile-button {
    display: block;
  }
  .header #site-header-inner {
    padding: 20px 0;
  }
  .header #site-header-inner .header-right {
    margin-right: 40px;
  }
  .tf-blog .main-content {
    margin-bottom: 50px;
  }
  .tf-blog .side-bar .widget {
    padding: 15px;
  }
  .detail-inner .content-top .title {
    padding: 0;
  }
  .detail-wrap .side-bar .widget {
    padding: 0;
  }
  .dashboard-content .inner-content.wallet .wallet-list .tf-wallet,
.dashboard-content .inner-content.follow .content-follow .card-author {
    width: calc(50% - 30px);
  }
  .history-filter .history-content,
.history-filter .history-sidebar {
    width: calc(100% - 30px);
    margin-left: 30px;
  }
  .history-filter .history-content {
    order: 1;
  }
  .history-filter .history-sidebar {
    margin-bottom: 50px;
  }
  .tf-banner-create .content-banner h2 {
    font-size: 50px;
  }
  .tf-banner-create.banner2 .content-banner h2 {
    font-size: 47px;
  }
  .tf-banner-create .content-banner .company,
.tf-banner-create .content-banner h2,
.tf-banner-create .content-banner p {
    margin-bottom: 15px;
  }
  .tf-banner-create .content-banner p {
    margin-bottom: 25px;
  }
  .tf-banner-create .content-banner .group-btn a.btn-1,
.tf-banner-create .content-banner .group-btn a.btn-2,
.tf-banner-create.banner2 .content-banner .group-btn a.btn-2 {
    padding: 9px 30px 11px 30px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .tf-banner-create.slide .content-slide .swiper-container .swiper-wrapper .swiper-slide .swiper-container-vertical {
    height: 540px;
  }
  .tf-banner-create.slide .content-slide .swiper-container .swiper-wrapper .swiper-slide .swiper-container-vertical img {
    width: 70%;
  }
  .tf-banner-create.banner2 .content-banner {
    margin-left: 0;
  }
  .add-nft-inner {
    margin-bottom: 50px;
  }
  .filter-menu {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .filter-menu li {
    margin-bottom: 20px;
  }
  .tf-baner-live-auction .banner-liver-auction-wrap .image {
    display: none;
  }
  .tf-explore-sidebar .top-option {
    flex-wrap: wrap;
  }
  .tf-explore-sidebar .top-option .left-option {
    margin-bottom: 20px;
  }
  .tf-create-wrap .tf-create.style-2 {
    width: calc(50% - 27px);
  }
  .tf-create-and-sell .tf-create {
    margin-bottom: 20px;
  }
  .tf-slider-item.style-2 .image {
    width: 50%;
  }
}
@media only screen and (max-width: 830px) {
  .tf-slider-item.style-4 .content-inner,
.tf-slider-item.style-2 .content-inner,
.tf-slider-item .content-inner {
    width: 100%;
  }
  .tf-slider-item .content-inner .heading,
.tf-slider-item .content-inner .sub-heading {
    max-width: 100%;
  }
  .tf-slider-item .content-inner h1,
.tf-slider-item .content-inner p {
    max-width: 100%;
  }
  .tf-slider-item.style-5 .image {
    display: block;
  }
  .tf-slider-item.style-5 .image .img-slider {
    width: 100%;
  }
  .tf-slider-item.style-5 .image .card-product {
    max-width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .detail-wrap {
    flex-wrap: wrap;
  }
  .detail-wrap .detail-inner {
    width: 100%;
    padding-right: 0px;
  }
  .detail-wrap .side-bar {
    width: 100%;
  }
  .top-banner{display:block !important}
  .tf-slider-item.style-4 .image {
    width: 100% !important;
  }

  body .top-banner .ani4 {
    max-width: 600px;
    min-width: 600px;
    max-height: 400px;
    min-height: 400px;
    object-fit: cover;
    margin: auto;
  }
  .dashboard-content .inner-content.wallet .wallet-list .tf-wallet,
.dashboard-content .inner-content.follow .content-follow .card-author {
    width: calc(100% - 30px);
  }
  .history-filter .history-content .inner .history-details .category-filter,
.tf-banner-create.slide .content-slide {
    display: none !important;
  }
  .dashboard-content .inner-content.inventory {
    width: 250% !important;
  }
  .overflow-table {
    overflow-x: auto;
    overflow-y: auto;
  }
  .tf-banner-create .thumb-banner {
    margin-bottom: 80px;
  }
  .tf-banner-create.banner2 .content-banner {
    margin-left: 0;
    margin-bottom: 60px;
  }
  .tf-banner-create.banner2 .thumb-image .thumb-1 {
    margin-left: 0px;
  }
  .tf-banner-create.banner3 .content-right .thumb .details-thumb3 {
    left: 0px;
  }
  .tf-banner-create.banner3 .content-right .thumb .details-thumb2 {
    right: -75px;
  }
  .tf-contact .image {
    margin-bottom: 50px;
  }
  .tf-create-wrap .tf-create.style-2 {
    width: calc(100% - 27px);
  }
  .tf-explore-sidebar-wrap.style-2 .sc-product,
.tf-explore-sidebar-wrap .sc-product {
    width: calc(100% - 30px);
  }
  .tf-item-detail-inner {
    flex-wrap: wrap;
  }
  .tf-item-detail-inner .image {
    width: 100%;
    margin-bottom: 50px;
  }
  .tab-history {
    padding-right: 10px;
  }
  .tf-heading {
    flex-wrap: wrap;
  }
  .tf-artis .slideThumbMain {
    margin-left: 0;
    margin-top: 0;
  }
  .tf-artis .content-thumb .avt {
    margin-right: 20px;
  }
  .dashboard-content .inner-content.profile {
    padding-left: 0;
  }
  .tf-slider-item.style-6 .image .sc-product:last-child {
    margin-bottom: 0;
  }
  .tf-section {
    padding-bottom: 60px !important;
  }
  .tf-banner-create.slide {
    padding: 0 0 60px;
  }
  .tf-banner-create.banner3 .content-banner {
    margin-top: 0;
  }
  .tf-slider-item.style-5 {
    padding: 120px 0 60px;
  }
  .tf-slider-item.style-5 .content-inner .img-star.star-1 {
    top: -3%;
  }
  .tf-slider-item.style-6 .image .sc-product:first-child,
.tf-slider-item.style-5 .image .card-product {
    margin-top: 50px;
  }
  .tf-slider-item.style-6,
.tf-slider-item.style-2,
.tf-slider-item,
.tf-slider-item.style-4 {
    padding: 100px 0 60px 0;
  }
}
@media only screen and (max-width: 600px) {
  .add-nft-inner .set-item fieldset,
.add-nft-inner .create-collection li,
.add-nft-inner .create-button li {
    width: calc(100% - 30px);
  }
  .add-nft-inner .bottom-button .tf-button.active {
    margin-bottom: 20px;
  }
  .banner-collection-inner .button-top {
    top: 5px;
  }
  .banner-collection-inner .button-top .btn-wishlish {
    padding: 7px 15px;
    font-size: 12px;
  }
  .banner-collection-inner .button-top .btn-collect, .banner-collection-inner .button-top .btn-option {
    width: 30px;
    height: 30px;
  }
  .banner-collection-inner .img-banner {
    bottom: -15px;
  }
  .tf-explore-sidebar .top-option .right-option {
    flex-wrap: wrap;
  }
  .tf-explore-sidebar .top-option .right-option .dropdown > a {
    min-width: 148px;
  }
  .tf-explore-sidebar .top-option .right-option .dropdown {
    margin-bottom: 20px;
  }
  .banner-liver-auction-wrap {
    padding: 30px;
  }
  .banner-liver-auction-wrap .content .heading {
    flex-wrap: wrap;
  }
  .banner-liver-auction-wrap .content .heading .title {
    margin-bottom: 20px;
  }
  .tf-account-wrap .tf-account {
    width: calc(100% - 30px);
    margin-bottom: 20px;
  }
  .tf-login .title-login::before, .tf-login .title-login::after {
    width: 23%;
  }
  .tf-slider-item.style-5 .image .card-product .infor-price {
    display: block;
  }
  .tf-slider-item.style-5 .image .btn-button .tf-button {
    margin-bottom: 20px;
  }
  .modal .modal-dialog .modal-content .modal-body {
    padding: 30px 20px;
  }
  .tf-slider-item.style-6 .image {
    display: block;
    margin-left: 50px;
  }
  .tf-slider-item.style-6 .image .sc-product {
    width: 50%;
  }
  .tf-slider-item.style-6 .image .sc-product:last-child {
    margin-left: 20px;
  }
  .tf-artis .content-thumb {
    display: block;
  }
  .tf-artis .content-thumb .avt {
    margin-bottom: 30px;
  }
  .tf-artis .content-thumb .content h4 {
    font-size: 46px;
  }
  .tf-heading .button {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 520px) {
  .add-nft-inner .blockchain-button li {
    width: calc(50% - 12px);
  }
  body .top-banner .ani4 img {max-width: 90%;min-width: 90%;}
 body .tf-item-detail-inner .image{max-height: 364px;min-height:364px;}
  body .top-banner .ani4 {
    max-width: 100%;
    min-width: 100%;
    max-height: 400px;
    min-height: 400px;
    object-fit: cover;
    margin: auto;
  }
  .thumb-banner .thumb,.thumb-banner .thumb img {
    max-height: 300px !important;
    min-height: 300px !important;
  }
  .card-countdown h4 {
    font-size: 24px !important;
  }
  .card-countdown .countdown_value span {
    font-size: 24px !important;
  }
  .popup-user .avatar_popup{transform : translateX(111px) !important}
  .tf-slider-item .content-inner .tf-button {
    margin-bottom: 20px;
  }
  .tf-slider-item.style-5 .image .card-product {
    padding: 30px;
  }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-next {
    right: 20px;
  }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-prev {
    right: 60px;
  }
}

@media only screen and (max-width: 1600px) {
  .header #site-header-inner #site-logo {
    margin-right: 30px;
 }
  .tf-item-detail-inner .image {
    width: 40%;
    margin-right: 30px;
 }
  .tf-item-detail-inner.style-2 .current-bid {
    margin-right: 0;
 }
  .tf-slider-item .image .card-infor {
    margin-left: 15%;
 }
}
@media only screen and (max-width: 1366px) {
  #main-nav > ul > li {
    padding: 31px 15px 31px 15px;
 }
  .header #site-header-inner .header-right .tf-button {
    padding: 11px 15px;
 }
  .tf-blog .tf-blog-item .title {
    flex-wrap: wrap;
 }
  .detail-inner .image.style-2 {
    margin-right: 0;
 }
  .detail-inner .image.style-2 img {
    width: 100%;
    margin-bottom: 20px;
 }
  .detail-wrap .detail-inner {
    width: 65%;
    padding-right: 30px;
 }
  .detail-wrap .side-bar {
    width: 35%;
 }
  .dashboard-user {
    margin-bottom: 50px;
 }
  .tf-explore-sidebar-wrap .sc-product {
    width: calc(50% - 30px);
 }
  .sidebar.sidebar-explore {
    padding-right: 0px;
 }
  .tf-explore-sidebar-wrap.style-2 .sc-product {
    width: calc(50% - 30px);
    margin-left: 30px;
 }
  .banner-liver-auction-wrap .image .img1 {
    right: 0;
 }
  .tf-slider-item.style-2 .content-inner, .tf-slider-item .content-inner {
    padding-top: 0;
    width: 50%;
 }
  .tf-slider-item .image {
    width: 50%;
    margin-left: 100px;
 }
  .tf-slider-item .image .img-slider-main {
    width: 70%;
 }
  .tf-slider-item .image .card-infor {
    margin-left: 0%;
    padding: 15px;
 }
  .tf-slider-item.style-5 .image {
    margin-left: 0px;
 }
  .tf-slider-item.style-5 .image .img-slider {
    width: 40%;
 }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-next {
    right: 50px;
 }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-prev {
    right: 90px;
    left: auto;
 }
  .tf-slider-item.style-6 {
    padding: 150px 15px 124px 15px;
 }
}
@media only screen and (max-width: 1199px) {
  .header #site-header-inner .header-right .user {
    display: none;
 }
 body .top-banner .ani4 {
  max-width: 400px !important;
  min-width: 400px !important;
}
  .footer .widget.widget-infor {
    padding-right: 0;
 }
  .footer .widget.widget-infor .social-item {
    flex-wrap: wrap;
 }
  .footer .widget.widget-infor .social-item li {
    margin-bottom: 10px;
 }
  .footer .widget.widget-menu .menu {
    margin-left: 0 !important;
    margin-right: 40px;
 }
  .footer .widget.widget-menu .menu:last-child {
    margin-right: 0;
 }
  .footer .widget.widget-subcribe {
    margin-left: 0;
 }
  .tf-blog .main-content {
    margin-right: 0;
 }
  .tf-banner-create .content-banner {
    margin-left: 0;
 }
  .tf-banner-create.banner2 .content-banner p, .tf-banner-create.banner2 .content-banner h2, .tf-banner-create.banner3 .content-banner, .tf-banner-create.banner3 .content-banner p, .tf-banner-create.banner2.style2 .content-banner p {
    margin-right: 0;
 }
  .tf-banner-create.banner2 .content-banner .star {
    left: 0;
 }
  .tf-banner-create.slide .content-slide {
    right: -9.5%;
 }
  .add-nft-inner {
    padding-right: 0px;
 }
  .filter-menu li {
    padding: 7.5px 10px;
    margin-right: 5px;
 }
  .tf-slider-item.style-4 .content-inner {
    padding-top: 0;
 }
  .tf-slider-item.style-4 .content-inner form {
    margin-right: 0;
 }
  .tf-slider-item.style-4 .content-inner .sub-heading {
    padding-right: 0;
 }
 .tf-slider-item.style-4 .content-inner p {
  padding-right: 0;
}
}
@media only screen and (max-width: 1100px) {
  .header #site-header-inner .header-right .tf-button {
    display: none;
 }
  .footer .widget.widget-menu {
    flex-wrap: wrap;
 }
  .footer .widget.widget-menu .menu {
    width: 50%;
    margin-right: 0;
    margin-bottom: 20px;
 }
  .top-menu {
    flex-wrap: wrap;
 }
  .top-menu .filter-menu li {
    margin-bottom: 20px;
 }
  .tf-baner-live-auction.style-2 .banner-liver-auction-wrap .image .img1 {
    bottom: 0%;
    right: 0%;
    width: 29%;
 }
  .tf-item-detail-inner.style-2 .current-bid {
    flex-wrap: wrap;
 }
  .countdown.style-2 {
    margin-bottom: 20px;
 }
  .tf-ranking {
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
 }
  .tf-ranking .content-ranking, .tf-ranking .title-ranking {
    width: 1100px;
 }
  .slider-home-3.swiper-container {
    width: 100% !important;
 }
  .slider-thump .swiper-slide.swiper-slide-active {
    width: 180px !important;
 }
  .tf-slider-item.style-6 {
    display: block;
 }
  .tf-slider-item.style-6 .content-inner {
    padding-top: 0;
    width: 100%;
    margin-bottom: 50px;
 }
  .tf-slider-item.style-6 .image {
    width: 100%;
    justify-content: flex-start;
 }
  .tf-slider-item.style-6 .image .sc-product {
    width: 35%;
 }
  .tf-slider-item.style-6 {
    padding: 150px 0px 124px 0px;
 }
  .tf-slider-item.style-6 .content-inner .btn-slider {
    margin-left: 0;
 }
}
@media only screen and (max-width: 991px) {
  .mobile-button {
    display: block;
 }
  .header #site-header-inner {
    padding: 20px 0;
 }
  .header #site-header-inner .header-right {
    margin-right: 40px;
 }
  .tf-blog .main-content {
    margin-bottom: 50px;
 }
  .tf-blog .side-bar .widget {
    padding: 15px;
 }
  .detail-inner .content-top .title {
    padding: 0;
 }
  .detail-wrap .side-bar .widget {
    padding: 0;
 }
  .dashboard-content .inner-content.wallet .wallet-list .tf-wallet, .dashboard-content .inner-content.follow .content-follow .card-author {
    width: calc(50% - 30px);
 }
  .history-filter .history-content, .history-filter .history-sidebar {
    width: calc(100% - 30px);
    margin-left: 30px;
 }
  .history-filter .history-content {
    order: 1;
 }
  .history-filter .history-sidebar {
    margin-bottom: 50px;
 }
  .tf-banner-create .content-banner h2 {
    font-size: 50px;
 }
  .tf-banner-create.banner2 .content-banner h2 {
    font-size: 47px;
 }
  .tf-banner-create .content-banner .company, .tf-banner-create .content-banner h2, .tf-banner-create .content-banner p {
    margin-bottom: 15px;
 }
  .tf-banner-create .content-banner p {
    margin-bottom: 25px;
 }
  .tf-banner-create .content-banner .group-btn a.btn-1, .tf-banner-create .content-banner .group-btn a.btn-2, .tf-banner-create.banner2 .content-banner .group-btn a.btn-2 {
    /* padding: 9px 30px 11px 30px !important; */
    padding: 9px 18px 11px 18px !important;
    margin-right: 15px !important;
    margin-bottom: 15px !important;
 }
  .tf-banner-create.slide .content-slide .swiper-container .swiper-wrapper .swiper-slide .swiper-container-vertical {
    height: 540px;
 }
  .tf-banner-create.slide .content-slide .swiper-container .swiper-wrapper .swiper-slide .swiper-container-vertical img {
    width: 70%;
 }
  .tf-banner-create.banner2 .content-banner {
    margin-left: 0;
 }
  .add-nft-inner {
    margin-bottom: 50px;
 }
  .filter-menu {
    flex-wrap: wrap;
    margin-bottom: 0;
 }
  .filter-menu li {
    margin-bottom: 20px;
 }
  .tf-baner-live-auction .banner-liver-auction-wrap .image {
    display: none;
 }
  .tf-explore-sidebar .top-option {
    flex-wrap: wrap;
 }
  .tf-explore-sidebar .top-option .left-option {
    margin-bottom: 20px;
 }
  .tf-create-wrap .tf-create.style-2 {
    width: calc(50% - 27px);
 }
  .tf-create-and-sell .tf-create {
    margin-bottom: 20px;
 }
  .tf-slider-item.style-2 .image {
    width: 50%;
 }
}
@media only screen and (max-width: 830px) {
  .tf-slider-item .image {
    display: none;
 }
  .tf-slider-item.style-4 .content-inner, .tf-slider-item.style-2 .content-inner, .tf-slider-item .content-inner {
    width: 100%;
 }
  .tf-slider-item .content-inner .heading, .tf-slider-item .content-inner .sub-heading {
    max-width: 100%;
 }
 .tf-slider-item .content-inner h1, .tf-slider-item .content-inner p {
  max-width: 100%;
}
  .tf-slider-item.style-5 .image {
    display: block;
 }
  .tf-slider-item.style-5 .image .img-slider {
    width: 100%;
 }
  .tf-slider-item.style-5 .image .card-product {
    max-width: 100%;
 }
}
@media only screen and (max-width: 767px) {
  .detail-wrap {
    flex-wrap: wrap;
 }
 .banner-collection-inner,.banner-collection-inner .img-bg{
  max-height:150px;
  min-height:150px;
 }

  .detail-wrap .detail-inner {
    width: 100%;
    padding-right: 0px;
 }
  .detail-wrap .side-bar {
    width: 100%;
 }
  .dashboard-content .inner-content.wallet .wallet-list .tf-wallet, .dashboard-content .inner-content.follow .content-follow .card-author {
    width: calc(100% - 30px);
 }
  .history-filter .history-content .inner .history-details .category-filter, .tf-banner-create.slide .content-slide {
    display: none;
 }
  .dashboard-content .inner-content.inventory {
    width: 250%;
 }
  .overflow-table {
    overflow-x: auto;
    overflow-y: auto;
 }
  .tf-banner-create .thumb-banner {
    margin-bottom: 80px;
 }
  .tf-banner-create.banner2 .content-banner {
    margin-left: 0;
    margin-bottom: 60px;
 }
  .tf-banner-create.banner2 .thumb-image .thumb-1 {
    margin-left: 0px;
 }
  .tf-banner-create.banner3 .content-right .thumb .details-thumb3 {
    left: 0px;
 }
  .tf-banner-create.banner3 .content-right .thumb .details-thumb2 {
    right: -75px;
 }
  .tf-contact .image {
    margin-bottom: 50px;
 }
  .tf-create-wrap .tf-create.style-2 {
    width: calc(100% - 27px);
 }
  .tf-explore-sidebar-wrap.style-2 .sc-product, .tf-explore-sidebar-wrap .sc-product {
    width: calc(100% - 30px);
 }
  .tf-item-detail-inner {
    flex-wrap: wrap;
 }
  .tf-item-detail-inner .image {
    width: 100%;
    margin-bottom: 50px;
 }
  .tab-history {
    padding-right: 10px;
 }
  .tf-heading {
    flex-wrap: wrap;
 }
  .tf-artis .slideThumbMain {
    margin-left: 0;
    margin-top: 0;
 }
  .tf-artis .content-thumb .avt {
    margin-right: 20px;
 }
  .dashboard-content .inner-content.profile {
    padding-left: 0;
 }
  .tf-slider-item.style-6 .image .sc-product:last-child {
    margin-bottom: 0;
 }
  .tf-section {
    padding-bottom: 60px !important;
 }
  .tf-banner-create.slide {
    padding: 0 0 60px;
 }
  .tf-banner-create.banner3 .content-banner {
    margin-top: 0;
 }
  .tf-slider-item.style-5 {
    padding: 120px 0 60px;
 }
  .tf-slider-item.style-5 .content-inner .img-star.star-1 {
    top: -3%;
 }
  .tf-slider-item.style-6 .image .sc-product:first-child, .tf-slider-item.style-5 .image .card-product {
    margin-top: 50px;
 }
  .tf-slider-item.style-6, .tf-slider-item.style-2, .tf-slider-item, .tf-slider-item.style-4 {
    padding: 100px 0 60px 0;
 }
}
@media only screen and (max-width: 600px) {
  .add-nft-inner .set-item fieldset, .add-nft-inner .create-collection li, .add-nft-inner .create-button li {
    width: calc(100% - 30px);
 }
  .add-nft-inner .bottom-button .tf-button.active {
    margin-bottom: 20px;
 }
  .banner-collection-inner .button-top {
    top: 5px;
 }
  .banner-collection-inner .button-top .btn-wishlish {
    padding: 7px 15px;
    font-size: 12px;
 }
  .banner-collection-inner .button-top .btn-collect, .banner-collection-inner .button-top .btn-option {
    width: 30px;
    height: 30px;
 }
  .banner-collection-inner .img-banner {
    bottom: -15px;
 }
  .tf-explore-sidebar .top-option .right-option {
    flex-wrap: wrap;
 }
  .tf-explore-sidebar .top-option .right-option .dropdown > a {
    min-width: 148px;
 }
  .tf-explore-sidebar .top-option .right-option .dropdown {
    margin-bottom: 20px;
 }
  .banner-liver-auction-wrap {
    padding: 30px;
 }
  .banner-liver-auction-wrap .content .heading {
    flex-wrap: wrap;
 }
  .banner-liver-auction-wrap .content .heading .title {
    margin-bottom: 20px;
 }
  .tf-account-wrap .tf-account {
    width: calc(100% - 30px);
    margin-bottom: 20px;
 }
  .tf-login .title-login::before, .tf-login .title-login::after {
    width: 23%;
 }
  .tf-slider-item.style-5 .image .card-product .infor-price {
    display: block;
 }
  .tf-slider-item.style-5 .image .btn-button .tf-button {
    margin-bottom: 20px;
 }
  .modal .modal-dialog .modal-content .modal-body {
    padding: 30px 20px;
 }
  .tf-slider-item.style-6 .image {
    display: block;
    margin-left: 50px;
 }
  .tf-slider-item.style-6 .image .sc-product {
    width: 50%;
 }
  .tf-slider-item.style-6 .image .sc-product:last-child {
    margin-left: 20px;
 }
  .tf-artis .content-thumb {
    display: block;
 }
  .tf-artis .content-thumb .avt {
    margin-bottom: 30px;
 }
  .tf-artis .content-thumb .content h4 {
    font-size: 46px;
 }
  .tf-heading .button {
    margin-top: 15px;
 }
}
@media only screen and (max-width: 520px) {
  .dropboxsplit{flex-direction: column;gap: 5px !important;}
  .add-nft-inner .drag-upload{padding: 14px 0 20px !important;}
  .add-nft-inner .blockchain-button li {
    width: calc(50% - 12px);
 }
  .tf-slider-item .content-inner .tf-button {
    margin-bottom: 20px;
 }
  .tf-slider-item.style-5 .image .card-product {
    padding: 30px;
 }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-next {
    right: 20px;
 }
  .tf-slider-item.style-5 .image .slider-card-product .swiper-button-prev {
    right: 60px;
 }
}
@media screen and (max-width:1280px){
  #main-nav > ul > li {
    float: left;
    padding: 0px 15px 0px 5px !important;
    min-height: 50px;
display: flex;
align-items: center;
}
.details-product .name a{font-size:12px !important}
.author img {
  width: 36px !important;
  height: 35px !important;
}
body .sc-product .bottom .details-product .current-bid .price .cash {
  font-size: 9px !important;
}
.footer .widget.widget-menu .menu {
  margin-left: 25px !important;
}
.footer .widget.widget-infor {
  padding-right: 0 !important;
}
.footer .widget.widget-menu .menu {
  margin-left: 25px !important;
}
.inventory .table-ranking .title-ranking .col-rankingg {
  width: 100% !important;
}
.inventory .table-ranking .content-ranking .col-rankingg {
  width: 100% !important;
}
.inventory .col-rankingg.nofication{width:150px !important}
.card-countdown .countdown_value span{font-size:25px !important;}
.card-countdown h4{font-size:25px;}

.header{padding:0px 0px;}

.search-item input {
  padding: 5px 15px !important;
}

}
@media screen and (min-width:1550px){
  .tf-container{width:1600px !important}
}

@media screen and (max-width:1250px){

  .tf-slider-item.style-4.top-banner .image {
    padding-top: 50px !important;
  }
}
@media screen and (max-width:1440px){
  body .top-banner .ani4 {
    max-height: 450px;
    min-height: 450px;
    object-fit: cover;
    margin: auto;
}
.card-countdown .countdown_value span {
  font-size: 25px !important;
}
/* .footer .widget.widget-menu .menu {
  margin-left: 30px !important;
} */
.footer .widget .widget-title {
  font-size: 15px !important;
}
.card-countdown .countdown_value span{font-size:35px;}
.card-countdown{text-align:center}
#main-nav >ul >li {
          padding: 31px 15px 31px 15px !important;
}      
  

body #root .tf-container {
  width: 95% !important;
}
body .top-banner .ani4 {
  max-width: 500px;
  min-width: 500px;
}
body .top-banner .ani4 img {
  max-height: 450px;
  min-height: 450px;
  object-fit: cover;
}
}
@media screen and (max-width:1330px){
  #root .tf-container{width:95% !important}
  .sc-product .bottom .details-product .current-bid .price .cash{font-size:11px !important}
}
@media screen and (max-width:1050px){
  #main-nav > ul > li > a{
    padding-right:10px !important;
  }
  #main-nav > ul > li {
    padding: 0px 10px 0px 5px !important;
  }
}
@media screen and (max-width:480px){
  body .top-banner .ani4 {
    max-width: 370px !important;
    min-width: 370px !important;
  }
  .tf-item-detail-inner .content .content-top .wishlish .number-wishlish{padding:12px 10px;}
  .tf-banner-create .content-banner h2{font-size:40px !important}
  body .tf-slider-item.style-4 .content-inner .heading{font-size:40px !important;
    line-height: 50px !important;}
    body .tf-slider-item.style-4 .content-inner h1{font-size:40px !important;
      line-height: 50px !important;}
    #logo_header{width:150px !important;}
}
@media screen and (max-width:390px){
  body .top-banner .ani4 {
    max-width: 310px !important;
    min-width: 310px !important;
  }
  #logo_header{width:135px !important;}
}
#menu-primary-menu{display:flex;align-items: center;}
@media screen and (max-width:990px){
  .header {padding: 10px 0px;}
  
}
@media screen and (max-width:575px){
  .list_for_sale_now .list_border{width:100% !important}
  .set-item1 .ending_date{margin-left:0px !important;}
  .set-item1 .starting_date{margin-right:0px !important;margin-bottom: 20px !important;}
  .tab-details .top{display:block !important}
}
@media screen and (max-width:990px){
  .search-model{width:95% !important}
}